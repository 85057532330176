import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  // List of input file names
  files: [],
  // % Loaded upload
  loaded: 0,
  type: null,
  errors: [],
  warnings: [],
  samples: [],
}

export const metabSlice = createSlice({
  name: "metab",
  initialState: initialState,
  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload
    },
    removeFile: (state) => {
      // state = initialState
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setWarnings: (state, action) => {
      state.warnings = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setFiles,
  removeFile,
  setType,
  setLoaded,
  setErrors,
  setWarnings,
} = metabSlice.actions

export default metabSlice.reducer
