import React from "react"
import Typography from "@material-ui/core/Typography"
import ErrorOutline from "@material-ui/icons/Error"

const NotFound = () => {
  return (
    <div className="Aligner Full-div">
      <Typography variant="h2" color="textSecondary" className="Aligner-item">
        <ErrorOutline fontSize="inherit" /> Page Not Found
      </Typography>
    </div>
  )
}

export default NotFound
