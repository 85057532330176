import React from "react"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { withTheme } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"

const Footer = (props) => {
  const theme = props.theme
  const logo = (
    <img
      src={process.env.PUBLIC_URL + "/images/linington_logo.png"}
      alt="Linington Lab Logo"
      height="50"
      style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
    />
  )

  return (
    <footer
      style={{ backgroundColor: theme.palette.primary.main, height: 100 }}
    >
      <Divider variant="middle" />
      <Typography
        className="text-center"
        gutterBottom
        style={{ color: theme.palette.primary.contrastText }}
      >
        © 2021 Copyright:
        <Link href="https://linington.chem.sfu.ca" color="inherit">
          {" "}
          linington.chem.sfu.ca
        </Link>
      </Typography>
      {logo}
    </footer>
  )
}

export default withTheme(Footer)
