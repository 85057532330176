import { createSlice } from "@reduxjs/toolkit"

export const initAttr = {
  minFreq: 1,
  maxFreq: 100,
  minActivity: 0,
  maxActivity: 0,
  minCluster: 0,
  maxCluster: 0,
  minRettime: 0,
  maxRettime: 0,
  minPrecmz: 0,
  maxPrecmz: 0,
}

export const outputSlice = createSlice({
  name: "output",
  initialState: {
    jobid: null,
    status: null,
    maxCommunity: 0,
    communityView: false,
    scatterData: [],
    scatterFilters: { ...initAttr },
    networkData: [],
    showNetworkLabels: true,
  },
  reducers: {
    setJobId: (state, action) => {
      state.jobid = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setMaxCommunity: (state, action) => {
      state.maxCommunity = action.payload
    },
    toggleCommunityView: (state) => {
      state.communityView = !state.communityView
    },
    setScatterData: (state, action) => {
      state.scatterData = action.payload
    },
    setNetworkData: (state, action) => {
      state.networkData = action.payload
    },
    setScatterFilters: (state, action) => {
      state.scatterFilters = action.payload
    },
    toggleShowNetworkLabels: (state) => {
      state.showNetworkLabels = !state.showNetworkLabels
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setJobId,
  setStatus,
  setMaxCommunity,
  toggleCommunityView,
  setScatterData,
  setNetworkData,
  setScatterFilters,
  toggleShowNetworkLabels,
} = outputSlice.actions

export default outputSlice.reducer
