import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"

import StyleLink from "../components/StyleLink"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
})

const buttonSpacing = {
  marginLeft: 15,
  marginRight: 15,
}

const Header = () => {
  const classes = useStyles()
  const logo = (
    <a
      href={process.env.PUBLIC_URL + "/"}
      style={{ display: "block", marginLeft: "auto", marginRight: "0%" }}
    >
      <img
        src={process.env.PUBLIC_URL + "/images/header_logo.png"}
        alt="NP Analyst Logo"
        height="30"
        width="auto"
      />
    </a>
  )
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <StyleLink to={window.location.origin} style={buttonSpacing}>
            Home
          </StyleLink>
          <StyleLink
            target="_blank"
            rel="noopener noreferrer"
            to="https://liningtonlab.github.io/npanalyst_documentation/quickstart-guide/"
            style={buttonSpacing}
          >
            Quick Start
          </StyleLink>
          <StyleLink
            target="_blank"
            rel="noopener noreferrer"
            to="https://liningtonlab.github.io/npanalyst_documentation/"
            style={buttonSpacing}
          >
            Docs
          </StyleLink>
          <StyleLink
            target="_blank"
            rel="noopener noreferrer"
            to="https://liningtonlab.github.io/npanalyst_documentation/faq/"
            style={buttonSpacing}
          >
            FAQ
          </StyleLink>
          <StyleLink to="/contact" style={buttonSpacing}>
            Contact
          </StyleLink>
          {logo}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header
