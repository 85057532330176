// eslint-disable-next-line
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query/react"
import activityReducer from "../features/submission/activitySlice"
import metabReducer from "../features/submission/metabSlice"
import sessionReducer from "../features/submission/sessionSlice"
import configReducer from "../features/submission/configSlice"
import outputReducer from "../features/output/outputSlice"
import { jobApi } from "../services/jobOutput"

export const store = configureStore({
  reducer: {
    activity: activityReducer,
    metab: metabReducer,
    session: sessionReducer,
    config: configReducer,
    output: outputReducer,
    [jobApi.reducerPath]: jobApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(jobApi.middleware),
})

setupListeners(store.dispatch)

export default store
