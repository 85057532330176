import React from "react"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: "14px",
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
  },
}))

const StyleLink = (props) => {
  const classes = useStyles()
  if (props.to && props.to.startsWith("http")) {
    return (
      <a className={classes.root} href={props.to} {...props}>
        {props.children}
      </a>
    )
  }
  return (
    <Link className={classes.root} {...props}>
      {props.children}
    </Link>
  )
}

export default StyleLink
