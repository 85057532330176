import { createSlice } from "@reduxjs/toolkit"
import { cloneDeep } from "lodash"

const initialState = {
  // Name of the activity file
  file: null,
  // % Loaded upload
  loaded: 0,
  // Results from upload
  // Samples
  samples: [],
  // Validation errors
  errors: [],
  // Validation warnings
  warnings: [],
}

export const activitySlice = createSlice({
  name: "activity",
  initialState: cloneDeep(initialState),
  reducers: {
    addFile: (state, action) => {
      state.file = action.payload
    },
    removeFile: (state) => {
      for (let [key, value] of Object.entries(initialState)) {
        state[key] = value
      }
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload
    },
    setSamples: (state, action) => {
      state.samples = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setWarnings: (state, action) => {
      state.warnings = action.payload
    },
    updateSettings: (state, action) => {},
  },
})

// Action creators are generated for each case reducer function
export const {
  addFile,
  removeFile,
  setLoaded,
  setSamples,
  setErrors,
  setWarnings,
} = activitySlice.actions

export default activitySlice.reducer
