import { createSlice } from "@reduxjs/toolkit"

export const sessionPages = {
  ACTIVITY: "ACTIVITY",
  MSTYPE: "MSTYPE",
  MZML: "MZML",
  CONFIG: "CONFIG",
}

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    page: sessionPages.ACTIVITY,
    jobid: null,
    email: "",
    description: "",
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
    addJobId: (state, action) => {
      state.jobid = action.payload
    },
    addEmail: (state, action) => {
      state.email = action.payload
    },
    addDescription: (state, action) => {
      state.description = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { addJobId, addEmail, addDescription, setPage } =
  sessionSlice.actions

export default sessionSlice.reducer
