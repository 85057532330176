import React from "react"
import loadable from "@loadable/component"
import { Switch, Route } from "react-router-dom"

import NotFound from "../components/NotFound"

// Route based dynamic loading to decrease initial load time
const Home = loadable(() => import("./Home"))
const OutputContainer = loadable(() =>
  import("../features/output/OutputContainer")
)
const ContactForm = loadable(() =>
  import("../features/contact/ContactContainer")
)

// The Main component renders one of the provided Routes
// The / route will only match when the pathname is exactly the string "/"
const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/contact" component={ContactForm} />
      <Route path="/output" component={OutputContainer} />
      <Route path="/search" component={Home} />
      <Route path="*" component={NotFound} />
    </Switch>
  </main>
)

export default Main
