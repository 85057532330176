import colormap from "colormap"

export const clusterColors = colormap({ colormap: "jet", nshades: 16 })

export function setClusterScoreColor(item, attr) {
  if (item.cluster_score > 0.75) {
    item[attr] = clusterColors[14] // red color
    // item[attr] = "#A50026"; // red color
  } else if (item.cluster_score > 0.5) {
    item[attr] = clusterColors[12]
    // item[attr] = "#D73027";
  } else if (item.cluster_score > 0.25) {
    item[attr] = clusterColors[10]
    // item[attr] = "#F46D43";
  } else if (item.cluster_score > 0) {
    item[attr] = clusterColors[8]
    // item[attr] = "#FDAE61";
  } else if (item.cluster_score > -0.25) {
    item[attr] = clusterColors[6]
    // item[attr] = "#ABD9E9";
  } else if (item.cluster_score > -0.5) {
    item[attr] = clusterColors[4]
    // item[attr] = "#74ADD1";
  } else if (item.cluster_score > -0.75) {
    item[attr] = clusterColors[2]
    // item[attr] = "#4575B4";
  } else {
    item[attr] = clusterColors[0]
    // item[attr] = "#313695"; // blue color
  }
}

export function createCommunityColormap(nCluster) {
  return colormap({ colormap: "rainbow", nshades: Math.max(nCluster, 9) })
}
