import { createSlice } from "@reduxjs/toolkit"

export const configSlice = createSlice({
  name: "config",
  initialState: {
    // These will be updated by the job creation action
    activity_score: 0.0,
    cluster_score: 0.0,
    // These defaults need to be set here
    mz_ppm: 30.0,
    rt_window: 0.03,
    min_intensity: 2e3,
    req_replicates: 2, // # replicates for replicate comparison
    req_baskets: 1, // # replicates for basketting
    // Store the full original config
    config_obj: null,
  },
  reducers: {
    setActivityScore: (state, action) => {
      state.activity_score = action.payload
    },
    setClusterScore: (state, action) => {
      state.cluster_score = action.payload
    },
    setMzPpm: (state, action) => {
      state.mz_ppm = action.payload
    },
    setRtWindow: (state, action) => {
      state.rt_window = action.payload
    },
    setMinIntensity: (state, action) => {
      state.min_intensity = action.payload
    },
    setReqReplicates: (state, action) => {
      state.req_replicates = action.payload
    },
    setReqBaskets: (state, action) => {
      state.req_baskets = action.payload
    },
    setConfigObject: (state, action) => {
      state.config_obj = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setActivityScore,
  setClusterScore,
  setConfigObject,
  setMzPpm,
  setRtWindow,
  setReqReplicates,
  setReqBaskets,
  setMinIntensity,
} = configSlice.actions

export default configSlice.reducer
