import React from "react"

import { createTheme, ThemeProvider } from "@material-ui/core/styles"

import Header from "./app/Header"
import Footer from "./app/Footer"
import Main from "./app/Main"

const theme = createTheme({
  palette: {
    primary: {
      main: "#333333",
    },
    secondary: {
      main: "#336699",
    },
    error: {
      main: "#cc0000",
    },
  },
  typography: {
    useNextVariants: true,
  },
})

const App = () => (
  <div>
    <ThemeProvider theme={theme}>
      <Header />
      <Main id="main" />
      <Footer />
    </ThemeProvider>
  </div>
)

export default App
